<template>
  <v-container v-cloak>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row v-if="emailStatus !== 'confirmed'">
      <v-col>
        <v-alert
          :value="alert"
          color="#F29398"
          dark
          border="top"
          icon="mdi-alert-circle-outline"
          transition="scale-transition"
        >
          <v-row align="center">
            <v-col class="grow"> Accede a tu correo para confirmar tu cuenta. </v-col>
            <v-col class="shrink">
              <v-btn color="#EC6D75" @click="reenviarCorreo">Renviar e-mail</v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn color="#EC6D75" @click="cerrarBoton">X</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="misLineaCreditos">
      <v-col>
        <h2>Mis Solicitudes</h2>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar por Nombre / Estado"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card>
          <v-data-table :search="search" :headers="headers" :items="misLineaCreditos">
            <template v-slot:item.acciones="{ item }">
              <v-btn class="mx-2" fab dark small color="primary" v-on:click="verLineaCredito(item.id)">
                <v-icon dark> mdi-magnify </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                v-on:click="editarLineaCredito(item.id, item.lineaCredito_id.id)"
                :disabled="
                  item.acciones !== 'Precalificación' &&
                  item.acciones !== 'Requerimiento' &&
                  item.acciones !== 'Análisis'
                "
              >
                <v-icon dark> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.nombre_estado="{ item }">
              <v-chip
                class="ma-2"
                v-if="
                  item.nombre_estado === 'Precalificación' ||
                  item.nombre_estado === 'Análisis' ||
                  item.nombre_estado === 'Requerimiento'
                "
                color="warning"
              >
                {{ item.nombre_estado }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.nombre_estado === 'Desistido' || item.nombre_estado === 'Cerrado'"
                color="error"
              >
                {{ item.nombre_estado }}
              </v-chip>
              <v-chip
                class="ma-2"
                v-if="item.nombre_estado === 'Calificación' || item.nombre_estado === 'Aprobado'"
                color="success"
              >
                {{ item.nombre_estado }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-alert prominent text type="info">
          <v-row align="center">
            <v-col class="grow"> No tienes asociado ningun Servicio. </v-col>
            <v-col class="shrink">
              <v-btn class="primary" @click="verCatalogo">Ver catálogo</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Dashboard',
  data: () => ({
    headers: [
      { text: '#', value: 'id' },
      { text: 'Nombre', value: 'lineaCredito_id.nombre' },
      { text: 'Estado', value: 'nombre_estado' },
      { text: 'Acciones', value: 'acciones' },
    ],
    misFideicomisos: [],
    misLineaCreditos: [],
    fideicomisos: [],
    lineaCreditos: [],
    listaSolicitudEstados: [],
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Dashboard',
        disabled: true,
        href: '/',
      },
    ],
    alert: true,
    emailStatus: localStorage.getItem('emailStatus'),
    search: '',
  }),
  methods: {
    getCreditos() {
      axios
        .get(`${API_URL}api/unidadGestionClientes/${localStorage.getItem('cliente_id')}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.misFideicomisos = response.data.fideicomisoClientes;
        });

      axios
        .get(`${API_URL}api/servicioClientes/listado/${localStorage.getItem('cliente_id')}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status !== 'error') {
            this.misLineaCreditos = response.data.lineaCreditoClientes.map((lcc) => {
              const nombreEstado = this.listaSolicitudEstados.filter(
                (solicitudEstado) => solicitudEstado.id === lcc.estado_id,
              )[0].nombre;
              return {
                ...lcc,
                nombre_estado: nombreEstado,
                acciones: nombreEstado,
              };
            });
            console.log(this.misLineaCreditos);
          }
        });
    },
    cerrarBoton() {
      this.alert = false;
    },

    getFideicomisos() {
      axios
        .get(`${API_URL}api/unidadGestion/`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.fideicomisos = response.data.fideicomisos;
        });
    },
    getListaSolicitudEstados() {
      axios
        .get(`${API_URL}api/solicitudEstado`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.listaSolicitudEstados = response.data.solicitudEstado;
          this.getCreditos();
        });
    },
    getLineaCreditos() {
      axios
        .get(`${API_URL}api/servicio/`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.lineaCreditos = response.data.lineaCreditos;
        });
    },
    redireccion() {
      const token = localStorage.getItem('token');
      if (token !== 'undefined' && token !== null && token !== '') {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            // Token válido
          })
          .catch(() => {
            this.$router.push('/login');
          });
      } else {
        this.$router.push('/login');
      }
    },
    verCatalogo() {
      this.$router.push('/catalogo');
    },
    async reenviarCorreo() {
      const query = {
        method: 'post',
        url: `${API_URL}api/usuarios/reenviarEmail`,
        data: {},
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios(query).then(() => {
        this.alert = false;
      });
    },
    verLineaCredito(solicitud) {
      this.$router.push(`/creditoView/${solicitud}`);
    },
    editarLineaCredito(lineaCreditoClienteId, operatoriaId) {
      this.$router.push(`/solicitud/${lineaCreditoClienteId}/1/2/${operatoriaId}`);
    },
  },
  async created() {
    this.redireccion();
    this.getListaSolicitudEstados();
    this.getFideicomisos();
    this.getLineaCreditos();
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px !important;
}
</style>
